<template>
  <nav class="sidebar-container">this is sidebar xx</nav>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
.sidebar-container {
  background-color: red;
  position: fixed;
  left: 1rem;
}
</style>
