import 'primeicons/primeicons.css'
import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import type { App } from 'vue'

const primeVue = PrimeVue

// https://primevue.org/theming/styled/#colors
const color = 'Violet'
const preset = definePreset(Aura, {
  semantic: {
    primary: {
      50: `{${color}.50}`,
      100: `{${color}.100}`,
      200: `{${color}.200}`,
      300: `{${color}.300}`,
      400: `{${color}.400}`,
      500: `{${color}.500}`,
      600: `{${color}.600}`,
      700: `{${color}.700}`,
      800: `{${color}.800}`,
      900: `{${color}.900}`,
      950: `{${color}.950}`,
    },
  },
})

const primeVueOpts = {
  theme: {
    preset,
    options: {
      prefix: 'pv',
      darkModeSelector: '.dark',
      cssLayer: false,
    },
  },
}

export default (app: App<Element>) => {
  app.use(primeVue, primeVueOpts)
  app.use(ToastService)
}
